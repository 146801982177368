import { useEffect, useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";

import Layout from "../layout/Layout";
import PageBanner from "../layout/PageBanner";

const Contact = () => {
  useEffect(() => {
    scroll.scrollToTop({ duration: 250});
  }, []);
  return (
    <Layout pageName={"Contact"}>
      <PageBanner pageName={"Contact"} />
      <div className="neoh_fn_contact_page">
        <div className="container">
          <div className="mw_500">
            <form
              action="/"
              method="post"
              className="contact_form"
              id="contact_form"
              autoComplete="off"
            >
              <div className="input_list">
                <ul>
                  <li>
                    <label htmlFor="name">Name</label>
                    <input id="name" type="text" placeholder="Your Name" />
                  </li>
                  <li>
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      type="text"
                      placeholder="youremail@mail.com"
                    />
                  </li>
                  <li>
                    <label htmlFor="subject">Subject</label>
                    <input id="subject" type="text" />
                  </li>
                  <li>
                    <label htmlFor="message">Message</label>
                    <textarea id="message" defaultValue={""} />
                  </li>
                  <li>
                    <a
                      id="send_message"
                      href="#"
                      className="neoh_fn_button only_text"
                    >
                      <span className="text">Send Message</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="returnmessage"
                data-success="Your message has been received, We will contact you soon."
              />
              <div className="empty_notice">
                <span>Please Fill Required Fields</span>
              </div>
            </form>
            <div className="desc_holder">
              <p>
                This form should be used to contact support staff in the
                event that you are unable to contact us through other means.
                We graciously request that you first attempt to contact us
                via Discord at the links provided on the site.
              </p>
              <p>
                Please do not send inquiries via this form, as it is not
                considered a time sensitive form of communication by our
                staff. Please make all requests via the proper Discord channel.
              </p>
            </div>
            {/* <div className="info_list">
              <ul>
                <li>
                  <p className="label">Address</p>
                  <h3 className="value">69 Queen St, Melbourne Australia</h3>
                </li>
                <li>
                  <p className="label">Phone</p>
                  <h3 className="value">
                    <a href="tel:+7068980751">(+706) 898-0751</a>
                  </h3>
                </li>
                <li>
                  <a href="mailto:example@domain.com" className="email">
                    example@domain.com
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Contact;
