import { useEffect } from "react";

import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { onWaypoint, loadWaypoints } from "../utilities/waypointhandler";
import { dataBgImg } from "../utilities/utilits";

const PageBanner = ({ pageName }) => {
  console.log(pageName);
  useEffect(() => {
    
    loadWaypoints(".neoh_fn_pagetitle .fn_animated_text");
    dataBgImg();
  }, []);
  return (
    <div className="neoh_fn_pagetitle">
      <div className="bg_overlay">
        <div className="bg_color" />
        <div className="bg_image" data-bg-img="//soundcity.city/img%2Frooftop-resized.jpg" />
      </div>
      <div className="pt_content">
        <div className="container">
          <Waypoint onEnter={onWaypoint("title")}>
            <h3
              className="fn_title fn_animated_text"
              data-wait={1000}
              data-speed={8}
              id="title"
            >
              {pageName}
            </h3>
          </Waypoint>

          <p className="fn_desc">
            <Link to="/">
              <a>Home</a>
            </Link>
            <span className="separator">/</span>
            <span className="current">{pageName}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default PageBanner;
