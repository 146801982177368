import { Waypoint } from "react-waypoint";
import { useEffect } from "react";

const ServicesComponent = () => {
  const onWaypoint = (selector) => (value) => {
    const text = document.getElementById(selector);
    let letters = text.textData.split(""),
      time = text.getAttribute("wait"),
      speed = text.getAttribute("speed");
    if (!time) {
      time = 0;
    }
    if (!speed) {
      speed = 4;
    }
    speed = speed / 100;
    text.classList.add("ready");

    if (!text.classList.contains("stop")) {
      text.classList.add("stop");
      setTimeout(() => {
        text.innerHTML = "";
        console.log(text.textData);
        letters.forEach((i, e) => {
          var span = document.createElement("span");
          span.textContent = i;
          span.style.animationDelay = e * speed + "s";
          text.append(span);
        });
      }, time);
    }
  };

  const load = () => {
    const fn_animated_text = document.querySelectorAll("#services .fn_animated_text");
    fn_animated_text.forEach((text) => {
      text.classList.add("ready");
      text.textData = text.innerHTML;
      text.innerHTML = "";
    });
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <section id="services">
      {/* Dividers */}
      <img
        src="svg/divider.svg"
        alt=""
        className="fn__svg fn__divider top_divider"
      />
      <img
        src="svg/divider.svg"
        alt=""
        className="fn__svg fn__divider bottom_divider"
      />
      {/* !Dividers */}
      <div className="container">
        {/* Main Title */}
        <div className="neoh_fn_title">
          <h3 className="fn_title">Get Started</h3>
          <div className="line">
            <span />
          </div>
        </div>
        {/* !Main Title */}
        {/* Services */}
        <div className="neoh_fn_services">
          <ul>
            <li>
              <div className="item">
                <div className="item_num">
                  <span>01</span>
                </div>
                <div className="item_content">
                  <h3 className="fn_title">Visit Sound City</h3>
                  <Waypoint onEnter={onWaypoint("steps1")}>
                    <p className="fn_desc fn_animated_text" id="steps1">
                      Come and enjoy the sights and sounds of Sound City.
                      From our specialty crafted cityscape to the custom
                      soundtrack, there's always a new "spot" to find!
                    </p>
                  </Waypoint>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="item_num">
                  <span>02</span>
                </div>
                <div className="item_content">
                  <h3 className="fn_title">Join our Discord</h3>
                  <Waypoint onEnter={onWaypoint("steps2")}>
                    <p className="fn_desc fn_animated_text" id="steps2">
                      Take it to the next level and join our Discord
                      community to share great deals you've found,
                      chat with friends, or even flaunt your photos!
                    </p>
                  </Waypoint>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="item_num">
                  <span>03</span>
                </div>
                <div className="item_content">
                  <h3 className="fn_title">Go Shopping</h3>
                  <Waypoint onEnter={onWaypoint("step3")}>
                    <p className="fn_desc fn_animated_text" id="step3">
                      Visit the shopping district and check out all of
                      the fun and unique stores! From novelty t-shirts,
                      to cute companions, there's no limit to the fun!
                    </p>
                  </Waypoint>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="item_num">
                  <span>04</span>
                </div>
                <div className="item_content">
                  <h3 className="fn_title">Start Your Adventure</h3>
                  <Waypoint onEnter={onWaypoint("steps4")}>
                    <p className="fn_desc fn_animated_text" id="steps4">
                      You just can't resist any more, it's time.
                      Take the next step and join our friendly roleplay
                      community and truly live the Sound City experience!
                    </p>
                  </Waypoint>
                </div>
              </div>
            </li>
          </ul>
        </div>
        {/* !Services */}
      </div>
    </section>
  );
};
export default ServicesComponent;
