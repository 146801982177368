import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faFlickr, faDeviantart } from '@fortawesome/free-brands-svg-icons';
// import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
  return (
    <footer id="footer">
      <div className="neoh_fn_footer">
        {/* Footer Top */}
        <div className="footer_top">
          <div className="container">
            <div className="ft_in">
              <div className="desc">
                <div className="img">
                  <img
                    src="//soundcity.city/img%2Ffooter-logo.png"
                    alt=""
                  />
                </div>
                <h3 className="fn_title">Follow Us on Social Media</h3>
                {/* <p className="fn_desc">
                  Keep up with all of the latest Sound City public events!

                </p> */}
              </div>
              {/* <div className="subscribe_form">
                <div className="subscribe_in">
                  <input type="text" placeholder="Email..." />
                  <a href="#" className="neoh_fn_button only_text">
                    <span className="text">Subscribe</span>
                  </a>
                </div>
                <div
                  className="returnmessage"
                  data-success="Your message has been received, We will contact you soon."
                  data-message="You have subscribed to our updates. Thank you"
                  data-invalid-email="Please enter valid email!"
                />
                <div className="empty_notice">
                  <span>Please enter your Email</span>
                </div>
              </div> */}
              <div className="neoh_fn_social_list">
                <ul>
                  {/* <li>
                    <a href="#">
                      <i className="fn-icon-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fn-icon-facebook" />
                    </a>
                  </li> */}
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faDiscord} size="lg"/>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faFlickr} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faDeviantart} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* !Footer Top */}
        {/* Footer Bottom */}
        <div className="footer_bottom">
          <div className="container">
            <CookieConsent
              buttonStyle={{ backgroundColor: "#78f701", fontSize: "13px" }}
            >
              This website uses cookies to enhance the user experience.
            </CookieConsent>
            <div className="fb_in">
              <div className="fb_left">
                <p>Copyright {new Date().getFullYear()} - <a href="//soundcity.city">Sound City, Ltd.</a></p>
              </div>
              <div className="fb_right">
                <ul>
                  {/* <li>
                    <Link to="/roadmap">RoadMap</Link>
                  </li> */}
                  <li>
                    <a href="#">Cookies</a>
                  </li>
                  <li>
                    <a href="#">Terms &amp; Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* !Footer Bottom */}
      </div>
    </footer>
  );
};
export default Footer;
