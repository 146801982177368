import { useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";

import AboutComponent from "../components/AboutComponent";

import GetStartedComponent from "../components/GetStartedComponent";
import WhyChooseUsComponent from "../components/WhyChooseUsComponent";
import Layout from "../layout/Layout";

import { Waypoint } from "react-waypoint";

import { onWaypoint, loadWaypoints } from "../utilities/waypointhandler";

const Index = () => {
  useEffect(() => {
    loadWaypoints(".hero_content .fn_animated_text");
    scroll.scrollToTop({ duration: 250});
  }, []);

  return (
    <Layout pageName={"Home Static"}>
      {/* Hero Header */}
      <div className="neoh_fn_hero">
        {/* Overlay (of hero header) */}
        <div className="bg_overlay">
          {/* Overlay Color */}
          <div className="bg_color" />
          {/* !Overlay Color */}
          {/* Overlay Image */}
          <div
            className="bg_image"
            data-bg-img="https://live.staticflickr.com/65535/52553001198_42afaef51a_6k.jpg"
          />
          {/* !Overlay Image */}
        </div>
        {/* Overlay (of hero header) */}
        <div className="hero_content">
          <div className="container">
            <div className="content">
              <Waypoint onEnter={onWaypoint("title")}>
                <h2
                  className="fn_title fn_animated_text"
                  title="sound city"
                  id="title"
                >
                  sound city
                </h2>
              </Waypoint>
              <Waypoint onEnter={onWaypoint("description")}>
                <p className="fn_desc fn_animated_text" id="description">
                  A better life, through technology.
                </p>
              </Waypoint>
            </div>
          </div>
          <Link
            activeClass="active"
            href="#about"
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="neoh_fn_down magic-hover magic-hover__square"
          >
            <span className="text">Scroll Down</span>
            <span className="icon">
              <img src="svg/right-arr.svg" alt="" className="fn__svg" />
            </span>
          </Link>
        </div>
      </div>
      {/* !Hero Header */}
      {/* About Section */}
      <AboutComponent />
      {/* !About Section */}
      {/* Services Section */}
      <GetStartedComponent />
      {/* !Services Section */}
      {/* <Drops /> */}
      {/* Investor Section */}
      <WhyChooseUsComponent />
      {/* !Investor Section */}
      {/* Blog Section */}
      {/* <BlogComponent /> */}
    </Layout>
  );
};
export default Index;
