import { useEffect } from "react";
import { Waypoint } from "react-waypoint";
import { Link, animateScroll as scroll } from "react-scroll";

import { onWaypoint, loadWaypoints } from "../utilities/waypointhandler";

import Layout from "../layout/Layout";
import PageBanner from "../layout/PageBanner";

const About = () => {
  useEffect(() => {
    loadWaypoints(".neoh_fn_aboutpage .fn_animated_text");
    scroll.scrollToTop({ duration: 250});
  }, []);
  return (
    <Layout pageName={"About"}>
      <PageBanner pageName={"About"} />
      <div className="neoh_fn_aboutpage">
        {/* Information Section */}
        <section id="information">
          <div className="container">
            {/* About Item #1 */}
            <div className="neoh_fn_about_item">
              <div className="img_item">
                <img src="img/about/1.jpg" alt="" />
              </div>
              <div className="content_item">
                <div className="neoh_fn_title" data-align="left">
                  <h3 className="fn_title">A Living Cybercity</h3>
                  <div className="line">
                    <span />
                  </div>
                </div>
                <div className="desc">
                  <p>
                    Set hundreds of years into the future, long after the rise
                    of Transhumanism and the movement towards symbiosis with
                    machines, Sound City rests as the jewel of the a dystopian
                    future.
                  </p>
                  <p>
                    Forged by a team of experienced cloud engineers, designers,
                    and gamers; Sound City leverages the latest technologies to
                    provide you with the most immersive and enjoyable experience
                    possible.
                  </p>
                </div>
                <div className="buttons">
                  <a
                    href="http://maps.secondlife.com/secondlife/Sound%20City/219/26/118"
                    target="_blank"
                    rel="noreferrer"
                    className="neoh_fn_button"
                  >
                    <span className="icon">
                      <img
                        src="svg/second_life.svg"
                        alt=""
                        className="fn__svg"
                      />
                    </span>
                    <span className="text">Second Life</span>
                  </a>
                  <a
                    href="https://discord.com/invite/Zjc7SNuQTP"
                    target="_blank"
                    rel="noreferrer"
                    className="neoh_fn_button"
                  >
                    <span className="icon">
                      <img src="svg/discord.svg" alt="" className="fn__svg" />
                    </span>
                    <span className="text">Discord</span>
                  </a>
                </div>
              </div>
            </div>
            {/* !About Item #1 */}
            {/* Guarantee List */}
            <div className="neoh_fn_gualist">
              <ul>
                <li>
                  <div className="item">
                    <span className="line" />
                    <img src="svg/shopping.svg" alt="" className="fn__svg" />
                    <h3 className="fn_title">Commerce</h3>
                    <Waypoint onEnter={onWaypoint("shopping")}>
                      <p className="fn_desc fn_animated_text" id="shopping">
                        Exclusive home to stores like Tomcast Streams,
                        Hot Tees, and neon dream; Sound City boasts
                        some of the most unique offerings available!
                      </p>
                    </Waypoint>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <span className="line" />
                    <img src="svg/party.svg" alt="" className="fn__svg" />
                    <h3 className="fn_title">Entertainment</h3>
                    <Waypoint onEnter={onWaypoint("entertainment")}>
                      <p
                        className="fn_desc fn_animated_text"
                        id="entertainment"
                      >
                        Watch movies at the community theatre, dance at
                        the colliseum in the Nou Pax Romana district, or
                        chat together at the strip. There's something for
                        everyone.
                      </p>
                    </Waypoint>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <span className="line" />
                    <img src="svg/cyber-arm.svg" alt="" className="fn__svg" />
                    <h3 className="fn_title">Adventure</h3>
                    <Waypoint onEnter={onWaypoint("adventure")}>
                      <p className="fn_desc fn_animated_text" id="adventure">
                        Sound City currently utilizes the DCS2 v3
                        Combat System. Applications are currently open.
                        If interested, please visit the information area.
                      </p>
                    </Waypoint>
                  </div>
                </li>
              </ul>
            </div>
            {/* !Guarantee List */}
            {/* About Item #2 */}
            <div className="neoh_fn_about_item reverse">
              <div className="img_item">
                <img src="img/about/2.jpg" alt="" />
              </div>
              <div className="content_item">
                <div className="neoh_fn_title">
                  <h3 className="fn_title">Unlimited Possibilities</h3>
                  <div className="line">
                    <span />
                  </div>
                </div>
                <div className="desc">
                  <p>
                    Whether you're visiting to shop at the exclusive stores,
                    found only at Sound City, or living a grand adventure there
                    is a place for you in Sound City.
                  </p>
                  <p>
                    And don't forget, future citizen, about the special private
                    events and sales just for residents of Sound City!
                  </p>
                </div>
                <div className="buttons">
                  <a href="#" className="neoh_fn_button only_text">
                    <span className="text">Join Community</span>
                  </a>
                </div>
              </div>
            </div>
            {/* !About Item #2 */}
          </div>
        </section>
        {/* !Information Section */}
        {/* Video Section */}
        <section id="video">
          {/* Dividers */}
          <img
            src="svg/divider.svg"
            alt=""
            className="fn__svg fn__divider top_divider"
          />
          <img
            src="svg/divider.svg"
            alt=""
            className="fn__svg fn__divider bottom_divider"
          />
          {/* !Dividers */}
          {/* Video Shortcode */}
          <div className="neoh_fn_video">
            <div className="bg_overlay">
              <div className="bg_image" data-bg-img="img/hero/rooftop.png" />
              <div className="bg_color" />
            </div>
            <div className="v_content">
              <a
                className="popup-youtube"
                href="//www.youtube.com/embed/Ni-Ia6kyWc4?autoplay=1"
              >
                <img src="svg/play.svg" alt="" className="fn__svg" />
              </a>
            </div>
          </div>
          {/* !Video Shortcode */}
        </section>
        {/* !Video Section */}
        {/* Team Section */}
        <section id="team">
          <div className="container">
            {/* Main Title */}
            <div className="neoh_fn_title">
              <h3 className="fn_title">Our Team Members</h3>
              <div className="line">
                <span />
              </div>
            </div>
            {/* !Main Title */}
            <div className="mw_650 fn_description">
              <Waypoint onEnter={onWaypoint("team_tagline")}>
                <p className="fn_desc fn_animated_text" id="team_tagline">
                  The most talented and creative minds have joined forces to
                  bring you the most advanced and entertaining virtual city in
                  existence.
                </p>
              </Waypoint>
            </div>
            {/* Team List Shortcode */}
            <div className="neoh_fn_team">
              <ul className="team_list">
                <li className="team_item">
                  <div className="t_item">
                    <div className="person_info">
                      <div className="img_holder">
                        <img src="//soundcity.city/img%2Fstaff%2Fepiphany.jpg" alt="" />
                      </div>
                      <div className="title_holder">
                        <h3 className="fn_title">Epiphany Absolute</h3>
                        <p className="fn_desc">Owner and Designer</p>
                      </div>
                    </div>
                    <div className="person_social">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fn-icon-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-pinterest" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="team_item">
                  <div className="t_item">
                    <div className="person_info">
                      <div className="img_holder">
                        <img src="img/author/2.jpg" alt="" />
                      </div>
                      <div className="title_holder">
                        <h3 className="fn_title">TBA</h3>
                        <p className="fn_desc">Head of Security</p>
                      </div>
                    </div>
                    <div className="person_social">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fn-icon-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-pinterest" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="team_item">
                  <div className="t_item">
                    <div className="person_info">
                      <div className="img_holder">
                        <img src="img/author/3.jpg" alt="" />
                      </div>
                      <div className="title_holder">
                        <h3 className="fn_title">TBA</h3>
                        <p className="fn_desc">Property Manager</p>
                      </div>
                    </div>
                    <div className="person_social">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fn-icon-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-pinterest" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                {/* <li className="team_item">
                  <div className="t_item">
                    <div className="person_info">
                      <div className="img_holder">
                        <img src="img/author/4.jpg" alt="" />
                      </div>
                      <div className="title_holder">
                        <h3 className="fn_title">Skylar Jarvis</h3>
                        <p className="fn_desc">2D Artist</p>
                      </div>
                    </div>
                    <div className="person_social">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fn-icon-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-pinterest" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="team_item">
                  <div className="t_item">
                    <div className="person_info">
                      <div className="img_holder">
                        <img src="img/author/5.jpg" alt="" />
                      </div>
                      <div className="title_holder">
                        <h3 className="fn_title">Avery Briggs</h3>
                        <p className="fn_desc">2D Artist</p>
                      </div>
                    </div>
                    <div className="person_social">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fn-icon-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-pinterest" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="team_item">
                  <div className="t_item">
                    <div className="person_info">
                      <div className="img_holder">
                        <img src="img/author/6.jpg" alt="" />
                      </div>
                      <div className="title_holder">
                        <h3 className="fn_title">Bernard Green</h3>
                        <p className="fn_desc">2D Artist</p>
                      </div>
                    </div>
                    <div className="person_social">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fn-icon-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-pinterest" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fn-icon-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li> */}
                
              </ul>
            </div>
            {/* !Team List Shortcode */}
          </div>
        </section>
        {/* !Team Section */}
      </div>
    </Layout>
  );
};
export default About;
