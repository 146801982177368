import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const AboutComponent = () => {
  return (
    <section id="about">
      <div className="container">
        {/* About Item #1 */}
        <div className="neoh_fn_about_item">
          <div className="img_item">
            <img src="//soundcity.city/img%2Fclass%2Fmedtech.jpg" alt="" />
          </div>
          <div className="content_item">
            <div className="neoh_fn_title">
              <h3 className="fn_title">More than Human</h3>
              <div className="line">
                <span />
              </div>
            </div>
            <div className="desc">
              <p>
                Become a citizen of a real digital city; complete with commerce,
                nightlife, housing, and more.
              </p>
              <p>
                Will you be a mogul merchant and walk the streets head held high, or
                slither in the shadows preying for your next nefarious deed?
              </p>
            </div>
            <div className="buttons">
              <a
                href="http://maps.secondlife.com/secondlife/Sound%20City/219/26/118"
                target="_blank"
                rel="noreferrer"
                className="neoh_fn_button"
              >
                <span className="icon">
                  <img src="svg/second_life.svg" alt="" className="fn__svg" />
                </span>
                <span className="text">Second Life</span>
              </a>
              <a
                href="https://discord.com/invite/Zjc7SNuQTP"
                target="_blank"
                rel="noreferrer"
                className="neoh_fn_button"
              >
                <span className="icon">
                  <img src="svg/discord.svg" alt="" className="fn__svg" />
                </span>
                <span className="text">Discord</span>
              </a>
            </div>
          </div>
        </div>
        {/* !About Item #1 */}
        {/* About Item #2 */}
        <div className="neoh_fn_about_item reverse">
          <div className="img_item">
            <img src="img/about/2.jpg" alt="" />
          </div>
          <div className="content_item">
            <div className="neoh_fn_title">
              <h3 className="fn_title">Rich Active City</h3>
              <div className="line">
                <span />
              </div>
            </div>
            <div className="desc">
              <p>
                Sound City is more than just a place to be. The city is filled
                with everything you can think of; adventure is around every
                corner.
              </p>
              <p>
                A rich lore and story across three regions lead by Epiphany Absolute, the creator
                and leader of Sound City.
              </p>
            </div>
            <div className="buttons">
              <Link to="/about" className="neoh_fn_button only_text">
                <span className="text">Learn More</span>
              </Link>
            </div>
          </div>
        </div>
        {/* !About Item #2 */}
      </div>
    </section>
  );
};
export default AboutComponent;
