

const onWaypoint = (selector) => (value) => {
    const text = document.getElementById(selector);
    let letters = text.textData.split(""),
      time = text.getAttribute("wait"),
      speed = text.getAttribute("speed");
    if (!time) {
      time = 0;
    }
    if (!speed) {
      speed = 4;
    }
    speed = speed / 100;
    text.classList.add("ready");

    if (!text.classList.contains("stop")) {
      text.classList.add("stop");
      setTimeout(() => {
        text.innerHTML = "";
        letters.forEach((i, e) => {
          var span = document.createElement("span");
          span.textContent = i;
          span.style.animationDelay = e * speed + "s";
          text.append(span);
        });
      }, time);
    }
  };


const loadWaypoints = (selector) => {
    const fn_animated_text = document.querySelectorAll(selector);
    fn_animated_text.forEach((text) => {
      text.classList.add("ready");
      text.setAttribute("textData", text.innerHTML);
      text.textData = text.innerHTML;
      text.innerHTML = "";
      // console.log(text);
    });
  };

  export { onWaypoint, loadWaypoints} ;